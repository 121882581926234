<template>
  <v-dialog v-model="dialog" max-width="300px" persistent>
    <v-form ref="form">
      <v-card>
        <edit-header :dialog-title="dialogTitle" />

        <v-card-text>
          <validation-observer ref="observer">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" name="Board" rules="required">
                    <v-text-field
                      v-model.trim="item.boardName"
                      label="Board Name"
                      prepend-icon="mdi-billboard"
                      autofocus
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>

        <v-divider />
        <edit-actions @exit="exit" @submit="onSubmit" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import crudEdit from '../../mixins/crudEdit'
import EditHeader from './_CommonEditHeader.vue'
import EditActions from './_CommonEditActions.vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    EditHeader,
    EditActions,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [crudEdit],
  data() {
    return {
      defaultItem: {
        id: '',
        boardName: '',
      },
    }
  },
}
</script>
<style scoped>
@import '../../assets/styles/crudDialog.css';
</style>
