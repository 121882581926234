import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"300px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form"},[_c(VCard,[_c('edit-header',{attrs:{"dialog-title":_vm.dialogTitle}}),_c(VCardText,[_c('validation-observer',{ref:"observer"},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Board","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Board Name","prepend-icon":"mdi-billboard","autofocus":"","error-messages":errors},model:{value:(_vm.item.boardName),callback:function ($$v) {_vm.$set(_vm.item, "boardName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.boardName"}})]}}])})],1)],1)],1)],1)],1),_c(VDivider),_c('edit-actions',{on:{"exit":_vm.exit,"submit":_vm.onSubmit}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }